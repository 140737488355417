<template>
  <div class="container-fluid">
    <!-- begin::Loading -->
    <div v-if="isMounted === false || isLoading === true" class="row">
      <div class="col-12 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
        <div class="loading-container">
          <div class="loading-block">
            <div class="blockui ml-auto mr-auto w-100">
              <span class="float-left">Gathering data...</span>
              <span class="spinner spinner-primary ml-auto" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end::Loading -->

    <!-- begin::Error -->
    <error-translation-alert
      v-else-if="isErrorActive"
      ref="errorAlert"
      :error="activeError"

      class="container"
    />
    <!-- end::Error -->

    <div v-else class="d-md-flex flex-row">
      <!-- begin::Aside -->
      <div class="flex-row-auto w-100 w-md-300px w-xl-350px">
        <div class="card card-custom">
          <div class="card-body pt-4">
            <!-- begin::Profile -->
            <div class="d-flex align-items-center">
              <div class="symbol symbol-50 mr-5 align-self-start align-self-xxl-center symbol-light-primary">
                <div class="symbol-label font-size-h5 font-weight-bold">
                  <font-awesome-icon :icon="['fas', 'cube']" />
                </div>
              </div>
              <div>
                <span class="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary">{{ device.serial }}</span>
              </div>
            </div>
            <!-- end::Profile -->

            <!-- begin::Navigation -->
            <div class="navi navi-bold navi-hover navi-active navi-link-rounded pt-5">
              <div class="navi-item mb-2">
                <router-link :to="{ name: '/admin/modules/view/:guid/ping-documents' }" active-class="active" class="navi-link py-4">
                  <span class="navi-icon mr-4">
                    <span class="svg-icon">
                      <font-awesome-icon :icon="['fas', 'network-wired']" class="h-20px w-20px" />
                    </span>
                  </span>
                  <span class="navi-text font-size-lg">Ping documents</span>
                </router-link>
              </div>
            </div>
            <!-- end::Navigation -->
          </div>
        </div>
      </div>
      <!-- end::Aside -->

      <!-- begin::Content -->
      <div class="flex-row-fluid mt-8 mt-md-0 ml-md-8">
        <router-view
          ref="router-view"
          :device="device"
        />
      </div>
      <!-- end::Content -->
    </div>
  </div>
</template>

<script>
import Device from '@/libs/classes/device';

import { errorComponentMixin } from '@vedicium/core-vue';
import errorTranslationAlert from '@/components/errors/translation.alert.vue';

export default {
  mixins: [errorComponentMixin],
  components: {
    errorTranslationAlert,
  },
  data () {
    return {
      isMounted: false,
      isLoading: false,

      device: null,
    };
  },
  async mounted () {
    this.$metronic.breadcrumbs.setBreadcrumbs([{ title: 'Admin' }, { title: 'Modules', route: '/admin/modules/overview' }]);

    this.$set(this, 'isLoading', true);
    try {
      this.$set(this, 'device', await Device.get(this.$route.params.guid));
    } catch (e) {
      console.error(e);
      this.$errors.handle(e, { component: this, ui_element: false });
    } finally {
      this.$set(this, 'isLoading', false);
      this.$set(this, 'isMounted', true);
    }

    await this.$nextTick();

    this.$metronic.breadcrumbs.appendBreadcrumb({ title: this.device.serial });
  },
};
</script>
